import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import addDashboard from "../../../utils/validation/addDashboard";
import { Close } from "@mui/icons-material";
import { InputForm, SelectFormm } from "../forms";
import { useNavigate, useParams } from "react-router-dom";
import productPopUp from "../../../utils/validation/productPopUp";
import { useGetAllProductListQuery } from "../../../store/feature/service/productListApiSlice";
import { useGetPricingSchemaByProductIdMutation } from "../../../store/feature/service/pricingConfigApiSlice";
import {
  useCreateCompanyListMutation,
  useCreateCompanySubscrireMutation,
  useCreateCustomerMutation,
  useGetCompanySubcriptionCmpIdMutation,
  useGetCompanySubsEmailMutation,
  useRegisterECDSAtoEntrustMutation,
  useRegisterWSOPtoEntrustMutation,
  useUpdateCompanySubscrireMutation,
} from "../../../store/feature/service/companyListApiSlice";
import { RootState, useAppSelector } from "../../../store";
import Swal from "sweetalert2";
import { FieldMode } from "../../../constants/enum/FieldMode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DetailDataSkeleton from "../../template/skeleton/DetailDataSkeleton";

const Product = ({ setValue, mode }: any) => {
  const navigate = useNavigate();
  const idParam = useParams();
  const [open, setOpen] = React.useState(false);
  const [compSubscribeBody, setCompSubscribeBody] = useState({});
  const [customerBody, setCustomerBody] = useState({});
  const [optProdList, setOptProdList] = useState([]);
  const [optPlanName, setOptPlanName] = useState([]);
  const [bodyStatus, setBodyStatus] = useState<any>({});
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);
  const { companyUid } = useAppSelector(
    (store: RootState) => store.companyList
  );
  const { data } = useGetAllProductListQuery({ pageSize: 1000 });
  const [getPricingSchemaByProductId, { data: pricingDataSchema }] =
    useGetPricingSchemaByProductIdMutation();

  const [
    getCompanySubcriptionCmpId,
    { data: companySubcriptionList, isSuccess: successCompSubsId },
  ] = useGetCompanySubcriptionCmpIdMutation();

  const [
    createCustomer,
    {
      data: customerData,
      isSuccess: createCustomerSuccess,
      isError: createCustomerError,
    },
  ] = useCreateCustomerMutation();

  const [
    createCompanySubscrire,
    { data: subscribeData, isSuccess: subscribeDataSuccess },
  ] = useCreateCompanySubscrireMutation();

  const [
    updateCompanySubscrire,
    { data: subscribeUpdateData, isSuccess: subscribeUpdateSuccess },
  ] = useUpdateCompanySubscrireMutation();

  const [
    registerWSOPentrust,
    {
      data: registerWSOPData,
      isSuccess: registerWSOPSuccess,
      isError: registerWSOPError,
      isLoading: registerWSOPLoading,
    },
  ] = useRegisterWSOPtoEntrustMutation();

  const [
    registerECDSAentrust,
    {
      data: registerECDSAData,
      isSuccess: registerECDSASuccess,
      isError: registerECDSAError,
      isLoading: registerECDSALoading,
    },
  ] = useRegisterECDSAtoEntrustMutation();

  const [
    getCompanySubsEmail,
    {
      data: cmpSubsEmail,
      isSuccess: cmpSubsEmailSuccess,
      isError: cmpSubsEmailError,
      error: respcmpSubsEmailErr,
    },
  ] = useGetCompanySubsEmailMutation();
  // const [{ data: createPayload }]: any = useCreateCompanyListMutation();

  useEffect(() => {
    if (data) {
      setOptProdList(
        data?.data?.data?.map((item: any) => ({
          label: item.prd_name,
          value: item.prd_uid,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (pricingDataSchema) {
      setOptPlanName(
        pricingDataSchema?.data?.pricing_plan_configs?.map((item: any) => ({
          label: item.pricing_plan_config_name,
          value: item.pricing_plan_config_uid,
        }))
      );
    }
  }, [pricingDataSchema]);

  useEffect(() => {
    if (companyUid || idParam.id !== undefined) {
      getCompanySubcriptionCmpId({ id: companyUid || idParam.id });
      console.log("company subs ==> ", companyUid);
    } else {
      Swal.fire({
        icon: "error",
        title: "Can not add Product",
        text: "Please create General Information first!",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#2e4054",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        setValue(0);
      });
    }
  }, [companyUid]);
  // console.log("haha data company ===> ");

  const {
    control: controlDashboard,
    reset: resetDashboard,
    handleSubmit: handleDashboard,
    setValue: setValueDashboard,
    watch,
    formState: { errors: errorDasboard },
  } = useForm<{
    productName: string;
    planName: string;
    adminName: string;
    adminEmail: string;
    adminPhoneNumber: string;
    password: string;
    confirmPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(productPopUp),
    defaultValues: {
      productName: "",
      planName: "",
      adminName: "",
      adminEmail: "",
      adminPhoneNumber: "",
      password: "",
      confirmPassword: "",
    },
  });
  const onAdd = (e: any) => {
    console.log("e ===> ", e);
    let compSubscribeBody = {
      customer_email: e.adminEmail,
      pricing_plan_config_uid: e.planName,
    };
    setCompSubscribeBody(compSubscribeBody);

    let customerBody = {
      cpn_uid: companyUid || idParam.id,
      customer_role_code: "UADM",
      email: e.adminEmail,
      first_name: e.adminName,
      last_name: "",
      password: e.password,
      phone_number: e.adminPhoneNumber,
      status: "active",
    };
    setCustomerBody(customerBody);
    getCompanySubsEmail({
      email: e.adminEmail,
      cmpId: watch("productName"),
    });
  };

  const handleStatusForm = (e: any) => {
    console.log("mantapuuuun ===> ", e.item);
    let body = {
      company_subscribe_uid: e.item[0].company_subscription_uid,
      status_middleware: e.status === "subscribed" ? "active" : "inactive",
      subscribe_status: e.status,
      user_email: e.email,
      user_status: "active",
      // e.status === "Subscribed" ? "active" : "inactive",
    };
    setBodyStatus(body);
  };

  const onAddUpdate = (e: any) => {
    // console.log('hahaha ===> ', )
    // console.log('hahaha body ===> ', )
    if (e.user_email === bodyStatus?.user_email) {
      updateCompanySubscrire(bodyStatus);
    } else {
      Swal.fire({
        icon: "error",
        title: "Plese Select Status First!",
        text: "Plese Select Status First!",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#2e4054",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  };

  const onRegisterEntrust = (e: any) => {
    // console.log('hahaha ===> ', )
    // console.log('hahaha body ===> ', )
    // if (e.user_email === bodyStatus?.user_email) {
    //   updateCompanySubscrire(bodyStatus);
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Plese Select Status First!",
    //     text: "Plese Select Status First!",
    //     showCancelButton: false,
    //     confirmButtonText: "OK",
    //     confirmButtonColor: "#2e4054",
    //     didOpen: () => Swal.getConfirmButton()?.focus(),
    //   });
    // }

    console.log("hight ===> ", e);
    const tempCompany = {
      company_subscribe_uid: e.prod_uid,
      user_email: e.user_email,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure, you want to register this account to Entrust Service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, register it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (e.product_code === "wsop") {
          registerWSOPentrust(tempCompany);
          console.log("wsop ===> ");
        } else if (e.product_code === "tokn") {
          console.log("tkon ===> ");
          registerECDSAentrust(tempCompany);
        }
      }
    });
  };

  useEffect(() => {
    if (cmpSubsEmailSuccess) {
      closeModal();
      Swal.fire({
        icon: "error",
        title: "Subscription Validation",
        text: "The following email account already has a subscription for the product you entered.",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#2e4054",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        getCompanySubcriptionCmpId({ id: companyUid || idParam.id });
      });
    } else if (cmpSubsEmailError) {
      createCustomer(customerBody);
    }
  }, [cmpSubsEmailSuccess, cmpSubsEmailError]);

  // Region for wait customer success
  useEffect(() => {
    console.log("error ===> ", respcmpSubsEmailErr);
    if (createCustomerSuccess || createCustomerError) {
      console.log("error ===> ", respcmpSubsEmailErr);
      createCompanySubscrire(compSubscribeBody);
      closeModal();
    }
  }, [createCustomerSuccess, createCustomerError]);
  const closeModal = () => {
    setOpen(false);
    // resetDashboard({
    //   productName: "",
    //   planName: "",
    // dashboardStatus: "",
    // });
  };

  const handleChangeProductName = (item: any) => {
    getPricingSchemaByProductId({ product_uid: item.value });
  };

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const endAdornmentConfirm = useMemo(() => {
    if (isShowPassConfirm) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPassConfirm]);

  const toggleShowPasswordConfirm = () => {
    setIsShowPassConfirm((prev) => !prev);
  };

  const optSelect = [
    {
      label: "Terminated",
      value: "terminated",
    },
    {
      label: "Subscribed",
      value: "subscribed",
    },
    {
      label: "Unsubscribed",
      value: "unsubscribed",
    },
  ];

  useEffect(() => {
    if (subscribeDataSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Product",
        showCancelButton: false,
        // confirmButtonText: "OK",
        // confirmButtonColor: "#051438",
        text: "Success Create Product",
      }).then(() => {
        getCompanySubcriptionCmpId({ id: companyUid || idParam.id });
      });
    }
  }, [subscribeDataSuccess]);

  useEffect(() => {
    if (subscribeUpdateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Update Status Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Update Status Success",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [subscribeUpdateSuccess]);

  useEffect(() => {
    if (registerWSOPSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Register WSOP",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Register WSOP to Entrust",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [registerWSOPSuccess]);

  useEffect(() => {
    if (registerECDSASuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Register Tokenization",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Register Tokenization to Entrust",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [registerECDSASuccess]);

  useEffect(() => {
    if (registerECDSAError) {
      Swal.fire({
        icon: "error",
        title: "Error Register Tokenization",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Register Tokenization to Entrust",
      });
    }
  }, [registerECDSAError]);

  useEffect(() => {
    if (registerWSOPError) {
      Swal.fire({
        icon: "error",
        title: "Error Register WSOP",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Register WSOP to Entrust",
      });
    }
  }, [registerWSOPError]);

  useEffect(() => {
    console.log(
      "companySubcriptionList data ===> ",
      companySubcriptionList?.data
    );
    // if (companySubcriptionList?.data) {
    //   companySubcriptionList?.data?.map((item: any) => {
    //     if (item?.company_subscriptions !== null) {
    //       const subscription = item?.company_subscriptions[0];
    //       setValueDashboard(
    //         `productName-${item.user_email}` as any,
    //         subscription?.product_name
    //       );
    //       setValueDashboard(
    //         `status-${item.user_email}` as any,
    //         subscription?.company_subscription_status
    //       );
    //       setValueDashboard(
    //         `planName-${item.user_email}` as any,
    //         subscription?.pricing_plan_config_name
    //       );
    //       setValueDashboard(
    //         `adminEmail-${item.user_email}` as any,
    //         item.user_email
    //       );
    //     }
    //   });
    // }
    if (companySubcriptionList?.data) {
      companySubcriptionList?.data?.forEach((item: any) => {
        if (item?.company_subscriptions) {
          item.company_subscriptions.forEach(
            (subscription: any, index: number) => {
              console.log(
                `Setting values for ${item.user_email}, subscription ${index}:`
              );
              console.log(`Product Name: ${subscription?.product_name}`);
              console.log(
                `Status: ${subscription?.company_subscription_status}`
              );
              console.log(
                `Plan Name: ${subscription?.pricing_plan_config_name}`
              );
              console.log(`Admin Email: ${item.user_email}`);
              setValueDashboard(
                `productName-${item.user_email}-${index}` as any,
                subscription?.product_name
              );
              setValueDashboard(
                `status-${item.user_email}-${index}` as any,
                subscription?.company_subscription_status
              );
              setValueDashboard(
                `planName-${item.user_email}-${index}` as any,
                subscription?.pricing_plan_config_name
              );
              setValueDashboard(
                `adminEmail-${item.user_email}-${index}` as any,
                item.user_email
              );
            }
          );
        }
      });
    }
  }, [companySubcriptionList]);

  const addProductModal = (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "30px",
          width: "50%",
        }}
        component="form"
        onSubmit={handleDashboard(onAdd)}
      >
        <Box sx={{ display: "flex" }} mb={1}>
          <Typography variant="h4" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
            Product
          </Typography>
          <IconButton onClick={() => closeModal()}>
            <Close
              sx={{
                height: "20px",
                width: "20px",
                padding: "0px",
                alignSelf: "center",
              }}
            />
          </IconButton>
        </Box>
        {/* <Box sx={{ height: "20px" }} /> */}
        <Typography variant="Subtitle16Bold">Product Information</Typography>
        <Grid container spacing={2} mt="2px">
          <Grid item xs={6} md={6}>
            <SelectFormm
              name="productName"
              label="Product Name"
              defaultValue={0}
              options={optProdList}
              placeholder="Please Select"
              isLoading={false}
              control={controlDashboard}
              errors={errorDasboard}
              isSearch
              required
              onChangeForm={handleChangeProductName}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <SelectFormm
              name="planName"
              label="Plan Name"
              defaultValue={0}
              options={optPlanName}
              placeholder="Please Select"
              isLoading={false}
              control={controlDashboard}
              errors={errorDasboard}
              isSearch
              required
            />
          </Grid>
        </Grid>
        <Typography variant="Subtitle16Bold">Admin Information</Typography>
        <Grid container spacing={2} mt="2px">
          <Grid item xs={6} md={6}>
            <InputForm
              name="adminName"
              label="Admin Name"
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Type Admin Name.."
            />
            <InputForm
              name="adminEmail"
              label="Admin Email"
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Type Admin Email.."
            />
            <InputForm
              name="adminPhoneNumber"
              label="Admin Phone Number"
              control={controlDashboard}
              errors={errorDasboard}
              maxLength={100}
              isLoading={false}
              type="number"
              placeholder="Type Admin Phone number.."
              showStartAdornment
              startAdornment={"+62"}
              sxEndAdornment={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                width: "4rem",
              }}
              required
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <InputForm
              name="password"
              label="Password"
              type={isShowPass ? "text" : "password"}
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Type Password.."
              showEndAdornment
              sxEndAdornment={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                width: "4rem",
              }}
              endAdornment={endAdornment}
              onClickEndAdornment={toggleShowPassword}
              sxFC={{ marginBottom: "1.5rem" }}
            />
            <InputForm
              name="confirmPassword"
              label="Confirm Password"
              type={isShowPassConfirm ? "text" : "password"}
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Type Confirm Password.."
              showEndAdornment
              sxEndAdornment={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                width: "4rem",
              }}
              endAdornment={endAdornmentConfirm}
              onClickEndAdornment={toggleShowPasswordConfirm}
              sxFC={{ marginBottom: "1.5rem" }}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{ marginLeft: "auto", marginRight: "8px" }}
            variant="contained"
            color="gray"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDashboard(async (e: any) => onAdd(e))}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const MyFormComponent = () => (
    // <div>
    //   {companySubcriptionList?.data?.map((item: any, index: any) => (
    //     <div key={index}>
    //       <Typography variant="Subtitle16Bold">Product Information</Typography>
    //       <Grid container spacing={2} mt="2px">
    //         <Grid item xs={6} md={6}>
    //           <InputForm
    //             name={`productName-${item.user_email}`}
    //             label={`Product Name`}
    //             disabled
    //             placeholder="none"
    //             isLoading={false}
    //             control={controlDashboard}
    //             errors={errorDasboard}
    //             // isSearch
    //             required
    //           />
    //           <SelectFormm
    //             name={`status-${item.user_email}`}
    //             label="Status"
    //             defaultValue={0}
    //             options={optSelect}
    //             placeholder="none"
    //             isLoading={false}
    //             control={controlDashboard}
    //             errors={errorDasboard}
    //             isSearch
    //             required
    //             disabled={mode === FieldMode.detail}
    //             onChangeForm={(e) =>
    //               handleStatusForm({
    //                 email: item.user_email,
    //                 status: e.value,
    //                 item: item?.company_subscriptions,
    //               })
    //             }
    //           />
    //         </Grid>
    //         <Grid item xs={6} md={6}>
    //           <InputForm
    //             name={`planName-${item.user_email}`}
    //             label="Plan Name"
    //             disabled
    //             placeholder="none"
    //             isLoading={false}
    //             control={controlDashboard}
    //             errors={errorDasboard}
    //             // isSearch
    //             required
    //           />
    //         </Grid>
    //       </Grid>
    //       <Typography variant="Subtitle16Bold">Admin Information</Typography>
    //       <Grid container spacing={2} mt="2px">
    //         <Grid item xs={6} md={6}>
    //           <InputForm
    //             name={`adminEmail-${item.user_email}`}
    //             label="Admin Email"
    //             control={controlDashboard}
    //             errors={errorDasboard}
    //             required
    //             maxLength={100}
    //             isLoading={false}
    //             placeholder="admin email.."
    //             disabled
    //           />
    //         </Grid>
    //         <Grid item xs={6} md={6} />
    //       </Grid>
    //       {mode !== FieldMode.detail && (
    //         <Stack
    //           spacing={2}
    //           direction="row"
    //           alignItems="center"
    //           justifyContent="flex-end"
    //           sx={{ padding: 2 }}
    //         >
    //           {item.company_subscriptions !== null && item.company_subscriptions[0].product_code === "wsop" ||
    //           item.company_subscriptions !== null && item.company_subscriptions[0].product_code === "tokn" ? (
    //             <Button
    //               // sx={{ marginLeft: "auto", marginRight: "8px" }}
    //               variant="contained"
    //               color="success"
    //               onClick={() => onRegisterEntrust(item)}
    //             >
    //               Register account to Entrust
    //             </Button>
    //           ) : null}
    //           <Button
    //             // sx={{ marginLeft: "auto", marginRight: "8px" }}
    //             variant="contained"
    //             onClick={() => onAddUpdate(item)}
    //           >
    //             Update
    //           </Button>
    //         </Stack>
    //       )}
    //       <hr />
    //     </div>
    //   ))}
    //   <br />
    // </div>
    <div>
      {companySubcriptionList?.data?.map((item: any, userIndex: any) => (
        <div key={userIndex}>
          <Typography variant="Subtitle16Bold">Product Information</Typography>
          {item?.company_subscriptions?.map(
            (subscription: any, subIndex: any) => (
              <div key={subIndex}>
                <Grid container spacing={2} mt="2px">
                  <Grid item xs={6} md={6}>
                    <InputForm
                      name={`productName-${item.user_email}-${subIndex}`}
                      label={`Product Name`}
                      disabled
                      placeholder="none"
                      isLoading={false}
                      control={controlDashboard}
                      errors={errorDasboard}
                      required
                    />
                    <SelectFormm
                      name={`status-${item.user_email}-${subIndex}`}
                      label="Status"
                      defaultValue={0}
                      options={optSelect}
                      placeholder="none"
                      isLoading={false}
                      control={controlDashboard}
                      errors={errorDasboard}
                      isSearch
                      required
                      disabled={mode === FieldMode.detail}
                      onChangeForm={(e) =>
                        handleStatusForm({
                          email: item.user_email,
                          status: e.value,
                          item: item?.company_subscriptions,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <InputForm
                      name={`planName-${item.user_email}-${subIndex}`}
                      label="Plan Name"
                      disabled
                      placeholder="none"
                      isLoading={false}
                      control={controlDashboard}
                      errors={errorDasboard}
                      required
                    />
                  </Grid>
                </Grid>
                <Typography variant="Subtitle16Bold">
                  Admin Information
                </Typography>
                <Grid container spacing={2} mt="2px">
                  <Grid item xs={6} md={6}>
                    <InputForm
                      name={`adminEmail-${item.user_email}-${subIndex}`}
                      label="Admin Email"
                      control={controlDashboard}
                      errors={errorDasboard}
                      required
                      maxLength={100}
                      isLoading={false}
                      placeholder="admin email.."
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} md={6} />
                </Grid>
                {mode !== FieldMode.detail && (
                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ padding: 2 }}
                  >
                    {(subscription.product_code === "wsop" ||
                      subscription.product_code === "tokn") && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          onRegisterEntrust({
                            product_code: subscription.product_code,
                            prod_uid: subscription.company_subscription_uid,
                            user_email: item.user_email
                          })
                        }
                      >
                        Register account to Entrust
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => onAddUpdate(item)}
                    >
                      Update
                    </Button>
                  </Stack>
                )}
                <hr />
              </div>
            )
          )}
        </div>
      ))}
      <br />
    </div>
  );

  return (
    <DetailDataSkeleton
      isLoading={false}
      isLoadingEvent={registerECDSALoading || registerWSOPLoading}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ padding: 2 }}
      >
        {mode !== FieldMode.detail && (
          <Button
            variant="contained"
            color="gray"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        )}
        {addProductModal}
      </Stack>
      {companySubcriptionList?.data.length > 0 ? (
        <MyFormComponent />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography variant="body1">No Product</Typography>
        </Box>
      )}
    </DetailDataSkeleton>
  );
};

export default Product;
